import { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Text from "../components/Text";
import Gallery from "../components/Gallery";
import MetricsOne from "../components/MetricsOne";
import MetricsTwo from "../components/MetricsTwo";
import MetricsThree from "../components/MetricsThree";
import Team from "../components/Team";
import Quote from "../components/Quote";
import Loader from "../components/Loader";

const LandingPage = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTime(2501);
    }, 2500);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <div className={`${time < 2500 ? "show" : "hidden"}`}>
        <Loader />
      </div>
      <div>
        <Hero />
        <Text />
        <Gallery />
        {windowSize[0] > 1400 && <MetricsOne />}
        {windowSize[0] <= 1400 && windowSize[0] > 870 && <MetricsTwo />}
        {windowSize[0] <= 870 && <MetricsThree />}
        <Team />
        <Quote />
      </div>
    </>
  );
};

export default LandingPage;
